<template>
  <div
    class="UseCaseView">
    <HeaderStep
      :logo="flow?.headerLogo"
      :theme="currentStep.headerTheme"
      :title="$t('flows.ibm.steps.useCase.title')"
      :subtitle="$t('flows.ibm.steps.useCase.subtitle')"
      :stepper-steps="stepperSteps"
      :active-stepper-step-id="currentStep.id"
    />

    <div class="container">
      <!-- Form -->
      <div>
        <div
          class="font-16 font-bold pb-2">
          {{ $t('shared.whatWouldYouLikeToAccomplish') }}

          <ErrorMessage
            class="mt-half"
            v-if="hasSubmitted && !session.useCase.isValidClientSide"
            :icon="null">
            {{ $t('shared.primaryObjectiveRequired') }}
          </ErrorMessage>
        </div>

        <InputSelect
          class="InputSelect-theme-radio"
          v-model="session.useCase.value"
          :is-tiles="true"
          :is-multi-select="false"
          :options="$i18n.messages[$i18n.locale].useCases"
          :is-invalid="hasSubmitted && !session.useCase.isValidClientSide"
          @select="handleSelect">
          <template
            v-slot:tile="{ option, isSelected }">
            <div
              class="__tile"
              :class="{ isSelected }">
              <IconRadio
                class="__radio"
                size="24"
                color="#99CCEE"
                :is-checked="isSelected"
              />

              <span
                class="__label"
                v-html="option.label">
              </span>
            </div>
          </template>
        </InputSelect>

        <InputSelectOptionOther
          :model-value="session.useCaseOther.value"
          :is-invalid="!session.useCaseOther.isValidClientSide"
          :labelQuestion="$t('shared.notSeeingYourUseCase')"
          :labelCta="$t('shared.tellUsHowYouWantToUseDomo')"
          :labelInstruction="`${$t('shared.tellUsHowYouWantToUseDomo')}.`"
          :placeholder="$t('shared.useCase')"
          ref="useCaseOtherInput"
          @update:model-value="handleOtherInput"
        />

        <!-- Error -->
      <ErrorTile
        class="mt-3"
        v-show="hasSubmitted && !isCurrentStepValidClientSide">
        {{ $t('shared.primaryObjectiveRequired') }}
      </ErrorTile>
      </div>

      <!-- Cta -->
      <div
        class="text-center mt-4">
        <ButtonBase
          class="uppercase"
          type="submit"
          :is-loading="isSubmitting"
          @click="handleSubmit">
          {{ $t('ctas.finishAndLaunchDomo') }}
        </ButtonBase>
      </div>
    </div>

  </div>
</template>


<script>
import HeaderStep from '@/components/HeaderStep.vue';
import InputSelect from '@/components/InputSelect.vue';
import InputSelectOptionOther from '@/components/InputSelectOptionOther.vue';
import IconRadio from '@/components/IconRadio.vue';
import ErrorTile from '@/components/ErrorTile.vue';
import ErrorMessage from '@/components/ErrorMessage.vue';
import ButtonBase from '@/components/ButtonBase.vue';

export default {
  name: 'UseCaseView',

  components: {
    HeaderStep,
    InputSelect,
    InputSelectOptionOther,
    IconRadio,
    ErrorTile,
    ErrorMessage,
    ButtonBase,
  },

  inject: [
    'domo',
    'flow',
    'session',
    'currentStep',
    'stepperSteps',
    'isCurrentStepValidClientSide',
  ],

  data() {
    return {
      isSubmitting: false,
      hasSubmitted: false,
    };
  },

  methods: {
    handleSelect() {
      this.session.useCaseOther.value = this.session.useCaseOther.defaultValue;
      this.$refs.useCaseOtherInput.close();
    },

    handleOtherInput(otherValue) {
      this.session.useCase.value = this.session.useCase.defaultValue;
      this.session.useCaseOther.value = otherValue;
    },

    async handleSubmit() {
      this.hasSubmitted = true;

      if (this.isSubmitting || !this.isCurrentStepValidClientSide) return;

      this.isSubmitting = true;

      await this.currentStep.submitHandler({
        domo: this.domo,
        session: this.session,
        currentStep: this.currentStep,
      });
    },
  },
};
</script>
